import StreamManager from '../util/StreamManager'
import StreamWrapper from '../util/StreamWrapper'
import createVolumeMeter from './function/createVolumeMeter'
import { getLogger } from '../util/log'
import {
    STREAM_TYPE,
    VIDEO_QUALITY_LOW,
    VIDEO_QUALITY_MEDIUM,
    VIDEO_QUALITY_HIGH,
    VIDEO_QUALITY_SUPER,
    VIDEO_QUALITY_720P,
    VIDEO_QUALITY_1080P
} from '../config'

const logger = getLogger('tencent stream-manager')
const isChrome = !!navigator.webkitGetUserMedia

// navigator.getDisplayMedia不支持分享音频
const isSupportScreenAudio = !!(navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia)

function getQualityProfile(opts) {
    return {
        width: opts.size.width,
        height: opts.size.height,
        frameRate: opts.fps,
        bitrate: opts.bandwidth || 1000
    }
}

const getDisplayMedia =
    window.navigator.mediaDevices && window.navigator.mediaDevices.getDisplayMedia
        ? window.navigator.mediaDevices.getDisplayMedia.bind(window.navigator.mediaDevices)
        : window.navigator && window.navigator.getDisplayMedia
        ? window.navigator.getDisplayMedia.bind(window.navigator)
        : null

export default class TencentStreamManager extends StreamManager {
    createAVStream(opts) {
        return new Promise((resolve, reject) => {
            if (!opts.type || opts.type === 'noDevice') {
                resolve()
            }
            const options = this.getAVStreamOptions(opts)
            let stream
            try {
                stream = global.TRTC.createStream(options)
            } catch (error) {
                let code = error && error.getCode()
                let name = ''
                switch (code) {
                    case '0x1003':
                        name = 'DevicesNotFoundError'
                        break
                }
                reject({
                    name: name,
                    error: error
                })
                return
            }

            // todo: size
            if (options.video) {
                stream.setVideoProfile(getQualityProfile(options))
            }
            stream.initialize().then(
                () => {
                    createVolumeMeter(stream)
                    stream.stream = stream.mediaStream_
                    resolve(stream)
                },
                (err) => {
                    logger.warn('stream initialize error:', err)
                    // 0x1003
                    if (err && err.code_ === 4099) {
                        reject(Object.assign({}, err, { name: 'DevicesNotFoundError' }))
                        return
                    }
                    reject(err)
                }
            )
        })
    }

    createScreenStream(opts) {
        return new Promise((resolve, reject) => {
            function createTencentScreenStream(videoSource, audioSource) {
                // 对 videoSource 和 audioSource 进行处理后
                let localStream = global.TRTC.createStream({
                    videoSource: videoSource,
                    audioSource: audioSource
                })
                logger.info('createTencentScreenStream', opts, (opts && opts.agoraDefinition) || '720p')
                localStream.setVideoProfile((opts && opts.agoraDefinition) || '720p')
                localStream.initialize().then(
                    () => {
                        localStream.stream = localStream.mediaStream_
                        localStream.sVideoSource = videoSource
                        localStream.sAudioSource = audioSource
                        resolve(localStream)
                    },
                    (err) => reject(err)
                )
            }

            if (getDisplayMedia) {
                getDisplayMedia({
                    video: true,
                    audio: opts && (opts.audio || opts.audio === undefined) && isSupportScreenAudio,
                    regionShare: !!opts.regionShare
                })
                    .then((mediaStream) => {
                        createTencentScreenStream(mediaStream.getVideoTracks()[0], mediaStream.getAudioTracks()[0])
                    })
                    .catch((err) => reject(err))
            } else if ('getDisplayMedia' in window.navigator) {
                navigator
                    .getDisplayMedia({
                        video: true,
                        audio: opts && (opts.audio || opts.audio === undefined) && isSupportScreenAudio
                    })
                    .then((mediaStream) => {
                        let videoSource = mediaStream.getVideoTracks()[0]
                        // 对 videoSource 和 audioSource 进行处理后
                        createTencentScreenStream(videoSource)
                    })
                    .catch((err) => reject(err))
            } else if (document.body.dataset.rtcExtensionId != null) {
                window.addEventListener('message', function once(event) {
                    const {
                        data: { type, streamId },
                        origin
                    } = event
                    // NOTE: you should discard foreign events
                    if (origin !== window.location.origin) {
                        logger.warn('ScreenStream: you should discard foreign event from origin:', origin)
                        // return;
                    }
                    // user chose a stream
                    if (type === 'STREAM_SUCCESS') {
                        window.removeEventListener('message', once)
                        navigator.mediaDevices
                            .getUserMedia({
                                audio: false,
                                video: {
                                    mandatory: {
                                        chromeMediaSource: 'desktop',
                                        chromeMediaSourceId: streamId,
                                        maxWidth: window.screen.width,
                                        maxHeight: window.screen.height
                                    }
                                }
                            })
                            .then((mediaStream) => {
                                let videoSource = mediaStream.getVideoTracks()[0]
                                createTencentScreenStream(videoSource)
                            })
                    }
                    if (type === 'STREAM_ERROR') {
                        window.removeEventListener('message', once)
                        reject(new Error('permission denied'))
                    }
                })
                window.postMessage({ type: 'STREAM_REQUEST' }, '*')
            } else {
                navigator.mediaDevices
                    .getUserMedia({
                        video: {
                            mandatory: {
                                chromeMediaSource: 'screen',
                                maxWidth: window.screen.width,
                                maxHeight: window.screen.height
                            }
                        }
                    })
                    .then((mediaStream) => {
                        let videoSource = mediaStream.getVideoTracks()[0]
                        createTencentScreenStream(videoSource)
                    })
                    .catch((e) => {
                        reject(new Error('not supported'))
                    })
            }
        })
    }

    createHTMLMediaStream({ videoElement, fps, quality, bandwidth }) {
        return new Promise((resolve, reject) => {
            if (!videoElement) {
                reject(new Error('videoElement required'))
            }
            const options = this.getHTMLMediaStreamOptions({ videoElement, fps })

            logger.info('createHTMLMediaStream', options)

            const stream = global.TRTC.createStream(options)
            stream.setVideoProfile({
                width: videoElement.videoWidth || 640,
                height: videoElement.videoHeight || 480,
                frameRate: fps || 15,
                bitrate: bandwidth
            })
            // todo: size
            stream.initialize().then(
                () => {
                    stream.stream = stream.mediaStream_
                    resolve(stream)
                },
                (err) => reject(err)
            )
        })
    }

    getAVStreamOptions(opts) {
        const fps = opts.fps || this.fps
        const options = {
            video: false,
            audio: false,
            screen: false,
            fps: fps,
            size: opts.size,
            bandwidth: opts.bandwidth,
            autoGainControl: false
        }
        if (opts.cameraId) {
            options.cameraId = opts.cameraId
        }
        if (opts.microphoneId) {
            options.microphoneId = opts.microphoneId
        }
        switch (opts.type) {
            case STREAM_TYPE.AV:
                options.video = true
                options.audio = true
                break
            case STREAM_TYPE.VIDEO:
                options.video = true
                break
            case STREAM_TYPE.AUDIO:
                options.audio = true
                break
        }
        return options
    }

    getHTMLMediaStreamOptions(opts) {
        let localStream = null
        // fixme(cc): firefox no audio
        const videoElement = opts.videoElement
        videoElement.captureStream = videoElement.captureStream || videoElement.mozCaptureStream
        if (videoElement.captureStream) {
            const fps = opts.fps || this.fps
            localStream = videoElement.captureStream(fps)
        } else {
            localStream = videoElement
            if (isChrome) {
                logger.warn(
                    'chrome should enable this flag(chrome://flags/#enable-experimental-web-platform-features) for this feature'
                )
            } else {
                logger.warn('the browser not support capturestream feature now')
            }
        }
        const streamWrapper = new StreamWrapper(localStream)
        const videoTrack = streamWrapper.videoTrack
        const audioTrack = streamWrapper.audioTrack

        let options = {}
        if (videoTrack) {
            options.videoSource = videoTrack
        }
        if (audioTrack) {
            options.audioSource = audioTrack
        }
        return options
    }
}
