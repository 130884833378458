import { getAudioContext, createAudioMeter } from '../../util/util'

export default function (stream) {
    if (stream.hasAudio()) {
        let audioContext = getAudioContext()
        let mediaStreamSource = audioContext.createMediaStreamSource(stream.mediaStream_)
        stream._volumeMeter = createAudioMeter(audioContext)
        mediaStreamSource.connect(stream._volumeMeter)
    }

    stream.getAudioLevel = () => {
        if (stream._volumeMeter) {
            return stream._volumeMeter.volume
        }
        return 0
    }
}
