import Events from 'events'
import Sleep from '../util/Sleep'
import BRTCClient from './BRTCClient'
import { getLogger } from '../util/log'
import { DEFAULT_MEDIA_BLOCK_CONFIG } from '../config'
import debounce from '../util/debounce'

const SUBSCRIBER_STATUS_PUBLISHED = 0
const SUBSCRIBER_STATUS_USER_UNSUBSCRIBING = 1
const SUBSCRIBER_STATUS_USER_UNSUBSCRIBED = 2
const SUBSCRIBER_STATUS_SUBSCRIBED = 3

const DISCONNECTED = 0
const CONNECTING = 1
const CONNECTED = 2
const CLOSING = 3

export default class BrtcSessionManager extends Events {
    constructor(log) {
        super()

        this._session = null
        this._playerId = null
        this._publisherStore = {}
        this._subscriberStore = {}

        this.status = DISCONNECTED
        this.isReconnecting = false
        this.hasLowStream = false

        this.logger = getLogger('session-' + (log ? log : '') + ':')

        this.subMuteQueue = {}
        this.subMute = async (media, userId, stream) => {
            if (this.isConnected() && !this.subMuteQueue[userId]) {
                this.logger.info('session manager submute called', userId)
                this.subMuteQueue[userId] = true
                setTimeout(() => {
                    delete this.subMuteQueue[userId]
                }, 100)
                try {
                    let options = {
                        video: true,
                        audio: true
                    }
                    if (media.video === true) {
                        options.video = false
                    }
                    await this._session.mutePeer(stream, options)
                } catch (error) {
                    this.logger.info('submute error', error && error.message)
                }
            }
        }
    }

    async createSession(params) {
        if (params) {
            params.lags = Object.assign(DEFAULT_MEDIA_BLOCK_CONFIG, params.lags)
            this.params = params
        }

        if (this.status === CONNECTING) {
            return
        }

        if (this.status === CONNECTED) {
            return this._session
        }

        this.status = CONNECTING

        try {
            this._session = new BRTCClient(this.params)

            this._session.on('publisher', (publisher) => {
                let playerId = publisher.userId
                this.logger.info(playerId + ' published')
                if (playerId !== this._playerId) {
                    // add for later subscribe
                    this.emit('addPublisher', null, {
                        playerId: playerId,
                        stream: publisher.stream
                    })
                }
            })

            this._session.on('updatePublisher', (publisher) => {
                let playerId = publisher.userId
                this.logger.info(playerId + ' update')
                if (playerId !== this._playerId) {
                    this.emit('updatePublisher', null, {
                        playerId: playerId,
                        stream: publisher.stream
                    })
                }
            })

            this._session.on('unpublish', (publisher) => {
                let playerId = publisher.userId
                this.logger.info(playerId + ' unpublished')
                this.emit('removePublisher', null, {
                    playerId: playerId,
                    stream: publisher.stream
                })
                this.emit('cleanStream', null, {
                    playerId: playerId,
                    stream: publisher.stream
                })

                if (this._subscriberStore[playerId]) {
                    if (this._subscriberStore[playerId]['status'] === SUBSCRIBER_STATUS_USER_UNSUBSCRIBING) {
                        this._subscriberStore[playerId]['status'] = SUBSCRIBER_STATUS_USER_UNSUBSCRIBED
                    }
                }
            })

            this._session.on('quit', () => {
                this.logger.info('session disconnected')

                this._session = null
                this.emit('sessionDisconnect')

                if (this.reconnectByUser) {
                    this.logger.info('rejoin by renew sessionId')
                    Promise.resolve().then(() => {
                        this.isReconnecting = true
                        this.createSession()
                    })
                }
                // 不是主动断开需要重新连接
                else if (this.status !== CLOSING) {
                    Promise.resolve().then(() => {
                        this.isReconnecting = true
                        this.createSession()
                    })
                }
                this.status = DISCONNECTED
            })

            this._session.on('rtcat-stats', (data) => {
                let playerId = data.userId
                let log = data.log

                if (data.isPublish) {
                    let uplinkVideoLossRate = log.uplinkVideoLossRate
                    let uplinkAudioLossRate = log.uplinkAudioLossRate
                    let uplinkVideoBandwidth = (8 * log.uplinkVideoBandwidth) / 1000
                    let uplinkAudioBandwidth = (8 * log.uplinkAudioBandwidth) / 1000
                    this.emit('uplinkStats', null, {
                        playerId: this._playerId,
                        uplinkVideoLossRate: uplinkVideoLossRate,
                        uplinkAudioLossRate: uplinkAudioLossRate,
                        uplinkVideoBandwidth: uplinkVideoBandwidth,
                        uplinkAudioBandwidth: uplinkAudioBandwidth,
                        rtt: log.rtt
                    })
                } else {
                    let downlinkVideoLossRate = log.downlinkVideoLossRate
                    let downlinkAudioLossRate = log.downlinkAudioLossRate
                    let downlinkVideoBandwidth = (8 * log.downlinkVideoBandwidth) / 1000
                    let downlinkAudioBandwidth = (8 * log.downlinkAudioBandwidth) / 1000
                    this.emit('downlinkStats', null, {
                        playerId: playerId,
                        downlinkVideoLossRate: downlinkVideoLossRate,
                        downlinkAudioLossRate: downlinkAudioLossRate,
                        downlinkVideoBandwidth: downlinkVideoBandwidth,
                        downlinkAudioBandwidth: downlinkAudioBandwidth
                    })
                }
            })

            this._session.on('flency-report', (data) => {
                this.emit('flency-report', null, { userId: data.userId })
            })

            await this._session.join()

            if (this.isReconnecting) {
                this.logger.info('session reconnected')
                this._playerId = null
                this.logger.info('republish after session reconnected')
                if (Object.keys(this._publisherStore).length !== 0) {
                    this.logger.info('republish after session reconnected', this._publisherStore.playerId)
                    this.emit('republish', null, {
                        playerId: this._publisherStore.playerId,
                        player: this._publisherStore.player
                    })
                }
                this.emit('reconnect')
            } else {
                this.logger.info('session join success')
            }

            this.status = CONNECTED
            this.reconnectByUser = false

            this.sessionId = Date.now()

            return this._session
        } catch (error) {
            this.status = DISCONNECTED
            this.logger.info(error)
            return await this.reconnectSession()
        }
    }

    async reconnectSession() {
        if (!this.isConnected()) {
            this.logger.info('reconnecting session...')
            if (this._session) {
                await this._session.destroy()
            }
            this.isReconnecting = true

            await new Sleep(5)

            return await this.createSession()
        } else {
            return this._session
        }
    }

    isConnected() {
        return this.status === CONNECTED
    }

    attachVideo(player) {
        if (this.isConnected() && player.__avStream) {
            this.logger.info('attach video', player.id)
            player.__avStream.unmuteVideo()
            return true
        }
        return false
    }

    detachVideo(player) {
        if (this.isConnected() && player.__avStream) {
            this.logger.info('detach video', player.id)
            player.__avStream.muteVideo()
            return false
        }
        return false
    }

    muteVideo(player) {
        if (this.isConnected() && player.__avStream) {
            this.logger.info('mute video', player.id)
            player.__avStream.muteVideo()
        }
    }

    unmuteVideo(player) {
        if (this.isConnected() && player.__avStream) {
            this.logger.info('unmute video', player.id)
            player.__avStream.unmuteVideo()
        }
    }

    attachAudio(player) {
        if (this.isConnected() && player.__avStream) {
            this.logger.info('attach audio', player.id)
            player.__avStream.unmuteAudio()
            return true
        }
        return false
    }

    detachAudio(player) {
        if (this.isConnected() && player.__avStream) {
            this.logger.info('detach audio', player.id)
            player.__avStream.muteAudio()
            return true
        }
        return false
    }

    // 开启双流模式
    async enableDualStream(options) {
        if (options) {
            this._session &&
                this._session.client &&
                (await this._session.client.setSmallStreamProfile({
                    width: options.width,
                    height: options.height,
                    bitrate: options.bitrate,
                    framerate: options.framerate
                }))
        }

        this._session && this._session.client && (await this._session.client.enableSmallStream())
        this.hasLowStream = true
    }

    // 关闭双流模式
    async disableDualStream() {
        this._session && this._session.client && (await this._session.client.disableSmallStream())
        this.hasLowStream = false
    }

    // 切换大小流
    switchStream({ highOrLow, stream }) {
        this._session &&
            this._session.client &&
            this._session.client.setRemoteVideoStreamType(stream, highOrLow ? 'small' : 'big')
    }

    async disconnect() {
        this.status = CLOSING
        if (this._session) {
            this.logger.info('session manager disconnect triggered')
            if (Object.keys(this._publisherStore).length !== 0) {
                try {
                    await this.publishAVClose({
                        playerId: this._publisherStore.playerId,
                        stream: this._publisherStore.stream
                    })
                    await this._session.destroy()
                    this._subscriberStore = {}
                } catch (error) {
                    this.logger.error(error)
                }
            } else {
                try {
                    await this._session.destroy()
                } catch (error) {
                    this.logger.error(error)
                }

                this._subscriberStore = {}
            }
        }
    }

    async _subscribe({ player, playerId, stream }) {
        let me = this
        let done = async () => {
            if (
                me._subscriberStore[playerId] &&
                me._subscriberStore[playerId]['status'] === SUBSCRIBER_STATUS_USER_UNSUBSCRIBING &&
                me.subscribeStreamRetryCount < 3
            ) {
                await new Sleep(1)
                me.subscribeStreamRetryCount++
                try {
                    await done()
                } catch (error) {
                    throw error
                }
            } else if (
                me._subscriberStore[playerId] &&
                me._subscriberStore[playerId]['status'] === SUBSCRIBER_STATUS_USER_UNSUBSCRIBING
            ) {
                me.logger.error('cancel subscribe for now because unscribing')
                throw new Error('cancel subscribe for now because unscribing')
            } else {
                me._subscriberStore[playerId] = {
                    playerId: playerId,
                    status: SUBSCRIBER_STATUS_PUBLISHED
                }
                me.logger.info(`${me._playerId} subscribe ${playerId}`)
                try {
                    let subscriber = await me._session.subscribe(stream)
                    me._subscriberStore[playerId]['status'] = SUBSCRIBER_STATUS_SUBSCRIBED
                    me.emit('addStream', null, {
                        playerId: playerId,
                        stream: subscriber.stream
                    })
                } catch (error) {
                    me.logger.error('subscribe', playerId, 'error:', event)
                    throw error
                }
            }
        }

        try {
            await done()
        } catch (error) {
            throw error
        }
    }

    async subscribe({ player, playerId, stream }) {
        this.subscribeStreamRetryCount = 0
        try {
            await this._subscribe({ player, playerId, stream })
        } catch (error) {
            throw error
        }
    }

    async unsubscribe({ playerId, stream }) {
        if (
            this._subscriberStore[playerId] &&
            [SUBSCRIBER_STATUS_PUBLISHED, SUBSCRIBER_STATUS_SUBSCRIBED].includes(
                this._subscriberStore[playerId]['status']
            )
        ) {
            this.logger.info('unsubscribe called for', playerId)

            this._subscriberStore[playerId]['status'] = SUBSCRIBER_STATUS_USER_UNSUBSCRIBING

            const sessionId = this.sessionId

            try {
                await this._session.unsubscribe(stream)
                this.emit('cleanStream', null, {
                    playerId: playerId,
                    holdStream: true
                })
                this._subscriberStore[playerId]['status'] = SUBSCRIBER_STATUS_USER_UNSUBSCRIBED
            } catch (error) {
                if (sessionId !== this.sessionId) {
                    this.logger.warn('old session unsubscribe, igonre')
                    return
                }

                this.logger.error(error && error.msg)
                this.emit('cleanStream', null, {
                    playerId: playerId,
                    holdStream: true
                })
                this._subscriberStore[playerId]['status'] = SUBSCRIBER_STATUS_USER_UNSUBSCRIBED
                throw error
            }
        }
    }

    async _publishAV({ player, playerId, stream, audioBandwidth, bandwidth, firPeriod }) {
        let me = this
        let done = async () => {
            if (me._session && me.isConnected() && me.params.playerId != playerId) {
                // 当前 sessionId 和 playerId 不一致，需要重新连接
                me.reconnectByUser = true
                await me._session.destroy()

                throw new Error('old session')
            } else if (me._session && me.isConnected()) {
                me.emit('addStream', null, { playerId, stream })

                try {
                    let publisher = await me._session.publish(stream)
                    me._playerId = playerId
                    me.emit('published', null, { playerId: playerId })
                } catch (error) {
                    me.logger.error(error)
                    throw error
                }
            } else if (me.publishStreamTryCount < 5) {
                await new Sleep(1)

                me.publishStreamTryCount++

                try {
                    await done()
                } catch (error) {
                    throw error
                }
            } else {
                me.logger.error('clean stream in publish')
                me.emit('cleanStream', null, { playerId, holdStream: true })
                throw new Error(`publish timeout ${playerId}`)
            }
        }

        try {
            await done()
        } catch (error) {
            throw error
        }
    }

    async publishAV({ player, playerId, stream, audioBandwidth = 64, bandwidth = 1000, firPeriod = 1000 } = {}) {
        this.logger.info('publish av', arguments)
        if (stream) {
            this._publisherStore = {
                player,
                playerId,
                stream: stream
            }
            this.publishStreamTryCount = 0

            try {
                await this._publishAV({
                    player,
                    playerId,
                    stream,
                    audioBandwidth,
                    bandwidth,
                    firPeriod
                })
            } catch (error) {
                throw error
            }
        } else {
            throw new Error('no stream')
        }
    }

    async publishAVClose({ playerId, stream }) {
        this._publisherStore = {}
        if (this._playerId === playerId) {
            this.logger.info('unpublish', this._playerId)
            if (this._session) {
                try {
                    await this._session.unpublish(stream)
                    this.hasLowStream && (await this.disableDualStream())
                    this._playerId = null
                    this.emit('unpublished', null, { playerId: playerId })
                } catch (error) {
                    this.logger.error('publishAVClose fail', error)
                    throw new Error(`publishAVClose fail ${playerId}`)
                }
            } else {
                throw new Error(`session is not joined`)
            }
        }
    }
}
